import { logToDatadog, recordActionToDatadog } from './datadog';
import { logToSentry } from './sentry';

export const logError = (
  error: unknown,
  level: 'info' | 'warning' | 'error' | 'debug' = 'error',
) => {
  let err: Error;

  if (typeof error === 'string') {
    err = new Error(error);
  } else if (error instanceof Error) {
    err = error;
  } else {
    err = new Error('Unknown error.');
  }

  logToSentry(err, level);
  logToDatadog(err, level);
};

export const recordAction = (actionName: string, context: Record<string, string>) => {
  recordActionToDatadog(actionName, context);
};
