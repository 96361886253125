import './logging/datadog.ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import { datadogRum } from '@datadog/browser-rum';
import {
  guardRoute,
  redirectToLogin,
  getUser,
  logout,
} from './common/utils/authentication/auth0.ts';
import { tenantStore } from '@/common/stores';

(async () => {
  if (window.location.pathname.startsWith('/s/')) {
    const SupplierBase = (await import('./appSupplier/SupplierBase.tsx')).SupplierBase;

    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <SupplierBase />
      </React.StrictMode>,
    );
    return;
  }

  const anonymizeEmail = (email: string | undefined): string | undefined => {
    if (!email) {
      return undefined;
    }
    const [username, domain] = email.split('@');

    if (username.length >= 2) {
      return `${username[0]}...${username[username.length - 1]}@${domain ?? ''}`;
    }

    return `...@${domain ?? ''}`;
  };

  if (window.location.pathname === '/logout') {
    logout();
  } else {
    guardRoute().then(async (authenticationState) => {
      if (authenticationState === 'NEEDS_LOGIN') {
        redirectToLogin();
        return;
      }

      const tenant = tenantStore.getState().tenant;

      const user = await getUser();

      if (!user) {
        throw new Error('User ID could not be retrieved');
      }

      if (!tenant || (tenant && !tenant.startsWith(user.pm.tenant))) {
        // If no tenant is set, we check the user's tenant from the auth token and set that as a default

        tenantStore.getState().setTenant(user.pm.tenant);
      }

      datadogRum.setUser({
        id: user.pm.urn,
        tenant: user.pm.tenant,
        assumedTenant: tenantStore.getState().tenant,
        role: user.pm.roles,
        email: anonymizeEmail(user.email),
        isAssumedTenant: user.pm.tenant != tenantStore.getState().tenant,
        isExternal: user.email?.split('@')[1].toLowerCase() !== 'packmatic.io',
      });

      let Base: React.FC;
      if (tenantStore.getState().tenant === 'pm') {
        Base = (await import('./appAdmin/BaseAdmin.tsx')).BaseAdmin;
      } else {
        Base = (await import('./Base.tsx')).Base;
      }

      ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
          <Base />
        </React.StrictMode>,
      );
    });
  }
})();
