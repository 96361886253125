import version from '@/VERSION?raw';
import { datadogRum } from '@datadog/browser-rum';

if (
  import.meta.env.VITE_DATADOG_APPLICATION_ID &&
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN &&
  import.meta.env.VITE_DATADOG_SERVICE &&
  import.meta.env.VITE_DATADOG_ENV
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: import.meta.env.VITE_DATADOG_SERVICE,
    env: import.meta.env.VITE_DATADOG_ENV,
    version: version.trim(),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      /http:\/\/localhost:.*/,
      'https://api.packmatic.io',
      'https://api-staging.packmatic.io',
    ],
    traceSampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
}

export const logToDatadog = (
  error: Error,
  level: 'info' | 'warning' | 'error' | 'debug' = 'error',
) => {
  if (datadogRum) {
    datadogRum.addError(error, { level });
  }
};

export const recordActionToDatadog = (actionName: string, context: Record<string, string>) => {
  if (datadogRum) {
    datadogRum.addAction(actionName, { context });
  }
};
