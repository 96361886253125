import { localStorageKeys } from '@/consts';
import { logError, recordAction } from '@/logging';
import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';

let auth0Client: Auth0Client | null = null;

type Auth0User = User & {
  pm: {
    urn: string;
    tenant: string;
    roles: string[];
  };
};

const getClient = async (): Promise<Auth0Client> => {
  if (auth0Client) return auth0Client;
  auth0Client = await createAuth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
  });
  return auth0Client;
};

export const isAuthenticated = async () => (await getClient()).isAuthenticated();

export const getAccessToken = async () => {
  const client = await getClient();
  try {
    return await client.getTokenSilently();
  } catch (e) {
    handleLoginError(e);
  }
};

export const redirectToLogin = async () => {
  const client = await getClient();
  return client.loginWithRedirect({
    appState: { target: window.location.pathname },
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
  });
};

export const logout = async () => {
  localStorage.removeItem(localStorageKeys.TENANT);
  localStorage.removeItem(localStorageKeys.TENDERING);

  const client = await getClient();
  return client.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

export const hasLoginRedirect = () => {
  const query = window.location.search;
  return query.includes('code=') && query.includes('state=');
};

export const handleRedirectCallback = async () => {
  localStorage.removeItem(localStorageKeys.TENANT);
  localStorage.removeItem(localStorageKeys.TENDERING);

  const client = await getClient();
  const redirect = await client.handleRedirectCallback<{ target: string }>();
  localStorage.setItem('redirectTarget', redirect.appState?.target ?? '/');
};

export const getUser = async (): Promise<Auth0User> => {
  const client = await getClient();

  const user = await client.getUser<Auth0User>();
  if (!user) throw new Error('getUser failed: Not logged in');
  return {
    ...user,
  };
};

export const guardRoute = async (): Promise<'LOGGED_IN' | 'NEEDS_LOGIN'> => {
  try {
    if (await isAuthenticated()) return 'LOGGED_IN';

    if (hasLoginRedirect()) {
      try {
        await handleRedirectCallback();
        return 'LOGGED_IN';
      } catch (e) {
        handleLoginError(e);
      }
    }
  } catch (_) {
    // nothing to catch
  }
  return 'NEEDS_LOGIN';
};

/**
 * Handles known login errors by logging the user out, thus clearing the local storage, and session cookies.
 * 1. Login required - (explained: https://www.notion.so/packmatic/Auth0-Login-failure-8b0f337d67c94b94bc9448dca98ba3bf?pvs=4)
 * 2. Missing refresh token - If the user has previously logged in without a refresh token.
 * 3. Unknown or invalid refresh token - If the user has an invalid refresh token.
 * @param error - The error to handle.
 */
export const handleLoginError = async (error: unknown) => {
  await new Promise((resolve) => setTimeout(resolve, 10));
  const knownErrors = [
    'missing refresh token',
    'unknown or invalid refresh token',
    'login required',
  ];
  // For known login errors we don't want to generate an error log.
  if (
    error instanceof Error &&
    knownErrors.some((knownError) => error.message.toLowerCase().includes(knownError))
  ) {
    recordAction('automatic_user_logout', { reason: error.message });
    await logout();
    return;
  }

  // For unknown login errors we still log the user out, but we also want to know what happened, with an error log.
  logError(error);
  await logout();
  return;
};
