import * as Sentry from '@sentry/react';
import version from '@/VERSION?raw';

Sentry.init({
  enabled: !!(import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_ENV),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: 'packa@' + version,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

export const logToSentry = (
  error: string | Error,
  level: 'info' | 'warning' | 'error' | 'debug' = 'error',
) => {
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    if (typeof error === 'string') {
      Sentry.captureMessage(error, level);
    } else {
      Sentry.captureException(error);
    }
  });
};
